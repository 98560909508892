<template>
  <div
    :key="componentSelected"
    data-componentname="card"
    :data-defaults="JSON.stringify({
      'border-color': 'accent',
      'bg': 'primary',
      'text': 'primary-text',
      'clip-path': 'inset(0 0 0 0 round 0 1rem 0 1rem)'
    })"
    :class="[
      `
      article cliped-corner
      shadow-light relative box-border
      flex w-full
      flex-wrap justify-start rounded-[3px]
      hover:cursor-pointer
      md:w-[calc(calc(100%-2rem)/2)]
      lg:w-[calc(calc(100%-4rem)/3)]
      `,
      {'bg-accent text-primary-text before:bg-primary': theme === 'dark'},
      {'bg-black text-black before:bg-white': theme === 'light'},
      theme,
      {'link-disabled': createModeAndEditPanelShowing('card').value}
    ]"
  >
    <div
      v-if="content || title"
      :class="[
        'grow p-[18px] pb-16',
      ]"
    >
      <div class="img-con relative left-0 top-0 pt-[57.42%]">
        <img
          width="100%"
          height="auto"
          :class="`
            absolute top-0 left-0 right-0 bottom-0 w-full h-full
            object-cover object-center rounded-[3px] cliped-corner
          `"
          :src="image"
          :alt="title"
        >
      </div>

      <div class="content py-4">
        <h4 class="w-full font-bold">
          {{ title }}
        </h4>
        <BlockContent v-if="content" :content="content" :prevent-links="true" />
      </div>

      <div class="[&>a]:text-primary-text absolute bottom-0 left-0 flex w-full items-end justify-center p-[18px]">
        <POButton
          v-if="cta?.linkType"
          :id="cta.gaTag"
          class="mt-2"
          :btn-style="cta.style"
          :color="cta.color"
          :uppercase="true"
          :link="link"
        >
          {{ cta.text }}
        </POButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  title: string;
  image: string;
  theme: Theme;
  content: BlockContent;
  cta: CTA;
}
const props = defineProps<Props>()
const link = computed(() => props?.cta[props?.cta?.linkType])

const states = useStatesStore()
const { componentSelected } = storeToRefs(states)
const { createModeAndEditPanelShowing } = states
</script>

<style lang="scss" scoped>
  .light {
    .read-more {
      @apply text-black;
    }
    &.article {
      @apply text-black;
    }
  }
  .read-more {
    @apply font-headerBold text-primary-text;
  }
  .article {
    @apply no-underline hover:no-underline;
    background-color: rgb(var(--card-border-color, var(--accent))/var(--tw-bg-opacity, 1));
    color: rgb(var(--card-text, var(--primary-text))/var(--tw-text-opacity, 1));
    &:before {
      background-color: rgb(var(--card-bg, var(--primary))/var(--tw-bg-opacity, 1));
    }
    &.cliped-corner {
      clip-path: var(--card-clip-path, var(--clip-path));
      &:before {
        clip-path: var(--card-clip-path, var(--clip-path));
      }
    }
  }
</style>
